import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Link from "../../utils/link"

import "./HeaderLinks.scss"

const HeaderLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "maven-home" }) {
        acf {
          contentBlocks {
            ... on WpPage_Acf_ContentBlocks_Header {
              links {
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <nav className="header-links">
      <ul className="header-links__ul">
        {data.wpPage.acf.contentBlocks[0].links.map((item) => {
          return (
            <li className="header-links__li" key={item.link.title}>
              <Link
                to={item.link.url}
                title={item.link.title}
                className="header-links__a"
                activeClassName="header-links__active"
                partiallyActive={true}
              >
                {item.link.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default HeaderLinks
